import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../../Components/Heading/Heading';
import { nft_art_gallery_data } from '../../../Data/nft_art_gallery_data';

export interface INftArtGalleryProps {
}

export function NftArtGallery(props: INftArtGalleryProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    //flex-[0_0_calc(15%-20px)] 


    return (
        <div className="mt-40 container mx-auto w-[79%]" id="Gallery">
            <div className="mx-auto w-fit">
                <Heading classes="text-black font-extrabold text-xl md:text-2xl xl:text-3xl 2xl:text-4xl text-left">
                    {t('NFT_ART_GALLERY', { returnObjects: true })[1]} <span className="text-cusOrenge"> {t('NFT_ART_GALLERY', { returnObjects: true })[0]}</span>
                </Heading>
            </div>

            <div className="flex flex-wrap justify-center items-center mt-10">
                {nft_art_gallery_data.map(data =>
                    <div
                        onClick={() => navigate(`/nftArtDetails/${data.id}`)}
                        className=" w-full h-full flex-[0_0_calc(100%-20px)] sm:flex-[0_0_calc(50%-20px)] md:flex-[0_0_calc(30%-20px)] xl:flex-[0_0_calc(25%-20px)] 2xl:flex-[0_0_calc(20%-20px)] mb-6 mx-2.5 cursor-pointer relative group rounded-lg overflow-hidden"
                        title="Click here"
                        key={data.id}>

                        <div className="bg-black absolute inset-0 opacity-60 z-40 justify-center items-center hidden group-hover:flex transition-all duration-300">
                            <button className="text-white hover:bg-cusOrenge hover:scale-110 transition-all duration-300 font-semibold text-sm px-3 py-2 border-2 rounded-lg">Details</button>
                        </div>

                        <img src={data.art} alt="" className="w-full h-full object-fill group-hover:scale-125 transition-all duration-300" loading="lazy" />
                    </div>
                )}
            </div>
        </div>
    );
}
