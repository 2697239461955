import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import Cookies from 'js-cookie';

import spainFlag from '../../Assets/Images/spainFlag.png';
import englishFlag from '../../Assets/Images/englishFlag.png';

export interface IHeaderProps {
}

export function Header(props: IHeaderProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [sideBarOpen, setSideBarOpen] = React.useState(false);
    const [headerBg, setHeaderBg] = React.useState(false)

    let lastScrollTop = 88;
    window.addEventListener('scroll', () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
            setHeaderBg(true)
        }
        else {
            setHeaderBg(false)
        }
    })
    function goTo(id: any) {
        let destination = document.querySelector(id);
        let yCoord = destination.offsetTop - 150;
        window.scrollTo({
            top: yCoord,
            behavior: "smooth"
        });
    }




    const myCurrentLangCode = Cookies.get('i18next') || 'en';
    const languageChangeByClick = () => {
        if (myCurrentLangCode === 'en') {
            i18next.changeLanguage('es')
        } else {
            i18next.changeLanguage('en')
        }
    }


    return (
        <div className={`w-full sticky top-0 py-8 z-50 ${headerBg || pathname.split('/')[1] === 'nftArtDetails' ? 'bg-[#b81f45] shadow-md backdrop-blur-sm' : ''}`}>
            <div className="container mx-auto w-[93%] xl:w-[88%] mxl:w-[85%] 2xl:w-[82%] 3xl:w-[79%] h-full flex justify-between items-center">

                <div className="flex justify-start items-center">
                    {/* <a href="/">
                        <div className="p-2 inline-block w-20 h-20 bg-white">
                            <img src={logo} alt="" className="w-full h-full" />
                        </div>
                    </a> */}
                    {pathname.split('/')[1] === 'nftArtDetails'

                        ? <button
                            onClick={() => navigate(-1)}
                            className="text-white hover:text-cusOrenge text-xs lg:text-sm xl:text-base mxl:text-lg font-semibold transition-all duration-300 flex justify-start items-center mr-8">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-4 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                            <p>Back</p>
                        </button>

                        : <div className="hidden md:flex justify-start items-center space-x-3 lg:space-x-4 xl:space-x-5 mxl:space-x-6 3xl:space-x-8">
                            <button onClick={() => goTo('#Project')}
                                className="text-white hover:text-cusOrenge text-xs lg:text-sm xl:text-base mxl:text-lg font-semibold transition-all duration-300">{t('Project')}</button>
                            <button onClick={() => goTo('#Gallery')}
                                className="text-white hover:text-cusOrenge text-xs lg:text-sm xl:text-base mxl:text-lg font-semibold transition-all duration-300">{t('Gallery')}</button>
                            <button onClick={() => goTo('#Native_Artists')}
                                className="text-white hover:text-cusOrenge text-xs lg:text-sm xl:text-base mxl:text-lg font-semibold transition-all duration-300">{t('Native_Artists')}</button>
                            <button onClick={() => goTo('#NFT_Artists')}
                                className="text-white hover:text-cusOrenge text-xs lg:text-sm xl:text-base mxl:text-lg font-semibold transition-all duration-300">{t('NFT_Artists')}</button>
                            <button onClick={() => goTo('#Curators')}
                                className="text-white hover:text-cusOrenge text-xs lg:text-sm xl:text-base mxl:text-lg font-semibold transition-all duration-300">{t('Curators')}</button>
                            <button onClick={() => goTo('#Partners')}
                                className="text-white hover:text-cusOrenge text-xs lg:text-sm xl:text-base mxl:text-lg font-semibold transition-all duration-300">{t('Partners')}</button>
                        </div>}
                </div>


                <div className="flex justify-between w-full md:w-auto md:justify-end items-center">
                    <div className="hidden md:flex justify-end items-center space-x-2.5 lg:space-x-4">
                        <a href="https://twitter.com/granchaconft" target="_blank" rel="noreferrer" className="group">
                            <svg className="w-4 xl:w-[18px] mxl:w-[22px]" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className="group-hover:fill-cusOrenge transition-all duration-300" d="M4.96302 13.7244C6.17393 13.7649 7.38068 13.5623 8.51194 13.1285C9.64321 12.6947 10.676 12.0385 11.5494 11.1987C12.4227 10.359 13.1189 9.35265 13.5967 8.23925C14.0745 7.12586 14.3243 5.92799 14.3313 4.71642C14.8862 4.02969 15.2983 3.23897 15.5433 2.39072C15.5616 2.32371 15.5601 2.25283 15.539 2.18663C15.518 2.12043 15.4782 2.06173 15.4246 2.0176C15.3709 1.97347 15.3057 1.94579 15.2366 1.93789C15.1676 1.92999 15.0978 1.94221 15.0356 1.97308C14.7452 2.11283 14.4184 2.15797 14.1011 2.10215C13.7837 2.04632 13.4919 1.89234 13.2667 1.6619C12.9792 1.34709 12.6315 1.09319 12.2441 0.915242C11.8567 0.737291 11.4375 0.63891 11.0114 0.625928C10.5853 0.612946 10.1609 0.685627 9.7634 0.839665C9.36589 0.993703 9.00334 1.22596 8.69723 1.52268C8.27811 1.92858 7.97112 2.436 7.8061 2.99563C7.64109 3.55526 7.62365 4.14806 7.75549 4.71642C5.01215 4.8802 3.12867 3.57814 1.62188 1.79292C1.57662 1.74166 1.5174 1.70469 1.45147 1.68652C1.38554 1.66836 1.31575 1.66978 1.25061 1.69062C1.18548 1.71147 1.12782 1.75083 1.08469 1.80389C1.04155 1.85696 1.0148 1.92144 1.0077 1.98946C0.720653 3.58176 0.927721 5.22377 1.6011 6.69495C2.27448 8.16613 3.38191 9.39603 4.77467 10.2195C3.84082 11.2904 2.5294 11.9594 1.11416 12.0866C1.03834 12.0991 0.968349 12.1351 0.913981 12.1894C0.859614 12.2438 0.823592 12.3137 0.810957 12.3895C0.798322 12.4653 0.809706 12.5432 0.843516 12.6122C0.877326 12.6812 0.931871 12.738 0.999515 12.7745C2.23076 13.3897 3.58663 13.7147 4.96302 13.7244" fill="#fff" />
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/granchaconft/" target="_blank" rel="noreferrer" className="group">
                            <svg className="w-4 xl:w-[18px] mxl:w-[22px]" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className="group-hover:stroke-cusOrenge transition-all duration-300" d="M10.9804 1.37195H4.73804C3.01427 1.37195 1.61688 2.76934 1.61688 4.49311V10.7354C1.61688 12.4592 3.01427 13.8566 4.73804 13.8566H10.9804C12.7041 13.8566 14.1015 12.4592 14.1015 10.7354V4.49311C14.1015 2.76934 12.7041 1.37195 10.9804 1.37195Z" stroke="#fff" strokeWidth="1.56058" strokeLinecap="round" strokeLinejoin="round" />
                                <path className="group-hover:stroke-cusOrenge transition-all duration-300" d="M7.8592 9.95515C9.15203 9.95515 10.2001 8.90711 10.2001 7.61428C10.2001 6.32145 9.15203 5.27341 7.8592 5.27341C6.56637 5.27341 5.51833 6.32145 5.51833 7.61428C5.51833 8.90711 6.56637 9.95515 7.8592 9.95515Z" stroke="#fff" strokeWidth="1.56058" strokeLinecap="round" strokeLinejoin="round" />
                                <path className="group-hover:stroke-cusOrenge transition-all duration-300" d="M11.3705 4.10297V4.10397" stroke="#fff" strokeWidth="1.56058" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </a>
                        <a href="https://www.facebook.com/impactnft" target="_blank" rel="noreferrer" className="group">
                            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className="w-[18px] xl:w-5 mxl:w-6 group-hover:fill-cusOrenge transition-all duration-300">
                                <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z" />
                            </svg>
                        </a>
                        <a href="https://www.youtube.com/channel/UC_aB5JtsEijh_mT6pBTFDhA" target="_blank" rel="noreferrer" className="group">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 xl:h-6 mxl:h-7" data-name="Layer 1" viewBox="0 0 24 24">
                                <path className="group-hover:fill-cusOrenge transition-all duration-300" d="M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z" fill="#fff" />
                            </svg>
                        </a>
                    </div>

                    <a href="https://api.whatsapp.com/send?phonse=59175564717&text=I%20want%20more%20information%20about%20IMPACT%20NFT" target="_blank" rel="noreferrer"
                        className="md:ml-4 lg:ml-5 xl:ml-6 mxl:ml-7 2xl:ml-10 flex justify-end items-center group" title="Contact us on WhatsApp">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 xl:w-[18px] mxl:w-[21px] group-hover:fill-cusOrenge transition-all duration-300 fill-white" viewBox="0 0 16 16">
                            <path d="M8.002 0h-.004C3.587 0 0 3.588 0 8a7.94 7.94 0 0 0 1.523 4.689l-.997 2.972 3.075-.983A7.93 7.93 0 0 0 8.002 16C12.413 16 16 12.411 16 8s-3.587-8-7.998-8zm4.655 11.297c-.193.545-.959.997-1.57 1.129-.418.089-.964.16-2.802-.602-2.351-.974-3.865-3.363-3.983-3.518-.113-.155-.95-1.265-.95-2.413s.583-1.707.818-1.947c.193-.197.512-.287.818-.287.099 0 .188.005.268.009.235.01.353.024.508.395.193.465.663 1.613.719 1.731.057.118.114.278.034.433-.075.16-.141.231-.259.367-.118.136-.23.24-.348.386-.108.127-.23.263-.094.498.136.23.606.997 1.298 1.613.893.795 1.617 1.049 1.876 1.157.193.08.423.061.564-.089.179-.193.4-.513.625-.828.16-.226.362-.254.574-.174.216.075 1.359.64 1.594.757.235.118.39.174.447.273.056.099.056.564-.137 1.11z" />
                        </svg>
                        <p className="font-semibold tracking-tighter lg:tracking-normal text-xs lg:text-sm mxl:text-base group-hover:text-cusOrenge transition-all duration-300 text-white ml-1 lg:ml-2">+591 75564717</p>
                    </a>

                    <div className="flex justify-end items-center">
                        <button onClick={languageChangeByClick} className="flex justify-end items-center ml-3 lg:ml-4 mxl:ml-6 2xl:ml-10 bg-white px-2.5 xl:px-3 py-0.5 mxl:py-1 rounded-full">
                            {myCurrentLangCode === 'en'
                                ? <>
                                    <img src={spainFlag} alt="" className="w-4 xl:w-5 2xl:w-6" />
                                    <p className="text-black text-xs mxl:text-sm font-semibold ml-1 lg:ml-2">Español</p>
                                </>
                                : <>
                                    <img src={englishFlag} alt="" className="w-4 xl:w-5 2xl:w-6" />
                                    <p className="text-black text-xs mxl:text-sm font-semibold ml-1 lg:ml-2">English</p>
                                </>}
                        </button>

                        <button className="md:hidden ml-3"
                            onClick={() => setSideBarOpen(!sideBarOpen)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-7 sm:w-8" fill="none" viewBox="0 0 24 24" stroke="#fff" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>

            </div>








            {/* side menubar for small devices */}
            <div className={`transition-all duration-200 fixed h-screen w-full inset-0 md:hidden overflow-auto
                ${!sideBarOpen ? "translate-x-[-100%]" : "translate-x-0 bg-white p-5"}`}>

                <div className="flex justify-between items-center">
                    <div className="flex justify-start items-center">

                        <a href="https://www.youtube.com/channel/UC_aB5JtsEijh_mT6pBTFDhA" target="_blank" rel="noreferrer" className="mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 bg-cusOrenge w-8 h-8 rounded-full justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 xl:h-6 mxl:h-7" data-name="Layer 1" viewBox="0 0 24 24">
                                <path className="group-hover:fill-cusOrenge transition-all duration-300" d="M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z" fill="#fff" />
                            </svg>
                        </a>

                        <a href="https://www.facebook.com/impactnft" target="_blank" rel="noreferrer" className="mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 bg-cusOrenge w-8 h-8 rounded-full justify-center items-center">
                            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className="w-[18px] xl:w-5 mxl:w-6 group-hover:fill-cusOrenge transition-all duration-300">
                                <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z" />
                            </svg>
                        </a>

                        <a href="https://www.instagram.com/granchaconft/" target="_blank" rel="noreferrer" className="mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 bg-cusOrenge w-8 h-8 rounded-full justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 lg:w-5 mxl:w-[22px] fill-white" viewBox="0 0 2476 2476">
                                <path d="M825.4 1238c0-227.9 184.7-412.7 412.6-412.7 227.9 0 412.7 184.8 412.7 412.7 0 227.9-184.8 412.7-412.7 412.7-227.9 0-412.6-184.8-412.6-412.7m-223.1 0c0 351.1 284.6 635.7 635.7 635.7s635.7-284.6 635.7-635.7-284.6-635.7-635.7-635.7S602.3 886.9 602.3 1238m1148-660.9c0 82 66.5 148.6 148.6 148.6 82 0 148.6-66.6 148.6-148.6s-66.5-148.5-148.6-148.5-148.6 66.5-148.6 148.5M737.8 2245.7c-120.7-5.5-186.3-25.6-229.9-42.6-57.8-22.5-99-49.3-142.4-92.6-43.3-43.3-70.2-84.5-92.6-142.3-17-43.6-37.1-109.2-42.6-229.9-6-130.5-7.2-169.7-7.2-500.3s1.3-369.7 7.2-500.3c5.5-120.7 25.7-186.2 42.6-229.9 22.5-57.8 49.3-99 92.6-142.4 43.3-43.3 84.5-70.2 142.4-92.6 43.6-17 109.2-37.1 229.9-42.6 130.5-6 169.7-7.2 500.2-7.2 330.6 0 369.7 1.3 500.3 7.2 120.7 5.5 186.2 25.7 229.9 42.6 57.8 22.4 99 49.3 142.4 92.6 43.3 43.3 70.1 84.6 92.6 142.4 17 43.6 37.1 109.2 42.6 229.9 6 130.6 7.2 169.7 7.2 500.3 0 330.5-1.2 369.7-7.2 500.3-5.5 120.7-25.7 186.3-42.6 229.9-22.5 57.8-49.3 99-92.6 142.3-43.3 43.3-84.6 70.1-142.4 92.6-43.6 17-109.2 37.1-229.9 42.6-130.5 6-169.7 7.2-500.3 7.2-330.5 0-369.7-1.2-500.2-7.2M727.6 7.5c-131.8 6-221.8 26.9-300.5 57.5-81.4 31.6-150.4 74-219.3 142.8C139 276.6 96.6 345.6 65 427.1 34.4 505.8 13.5 595.8 7.5 727.6 1.4 859.6 0 901.8 0 1238s1.4 378.4 7.5 510.4c6 131.8 26.9 221.8 57.5 300.5 31.6 81.4 73.9 150.5 142.8 219.3 68.8 68.8 137.8 111.1 219.3 142.8 78.8 30.6 168.7 51.5 300.5 57.5 132.1 6 174.2 7.5 510.4 7.5 336.3 0 378.4-1.4 510.4-7.5 131.8-6 221.8-26.9 300.5-57.5 81.4-31.7 150.4-74 219.3-142.8 68.8-68.8 111.1-137.9 142.8-219.3 30.6-78.7 51.6-168.7 57.5-300.5 6-132.1 7.4-174.2 7.4-510.4s-1.4-378.4-7.4-510.4c-6-131.8-26.9-221.8-57.5-300.5-31.7-81.4-74-150.4-142.8-219.3C2199.4 139 2130.3 96.6 2049 65c-78.8-30.6-168.8-51.6-300.5-57.5-132-6-174.2-7.5-510.4-7.5-336.3 0-378.4 1.4-510.5 7.5" />
                            </svg>
                        </a>

                        <a href="https://twitter.com/granchaconft" target="_blank" rel="noreferrer" className="mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 bg-cusOrenge w-8 h-8 rounded-full justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 lg:w-5 mxl:w-6 fill-white" viewBox="0 0 512 512">
                                <path d="M492 109.5c-17.4 7.7-36 12.9-55.6 15.3 20-12 35.4-31 42.6-53.6-18.7 11.1-39.4 19.2-61.5 23.5C399.8 75.8 374.6 64 346.8 64c-53.5 0-96.8 43.4-96.8 96.9 0 7.6.8 15 2.5 22.1-80.5-4-151.9-42.6-199.6-101.3-8.3 14.3-13.1 31-13.1 48.7 0 33.6 17.2 63.3 43.2 80.7-16-.4-31-4.8-44-12.1v1.2c0 47 33.4 86.1 77.7 95-8.1 2.2-16.7 3.4-25.5 3.4-6.2 0-12.3-.6-18.2-1.8 12.3 38.5 48.1 66.5 90.5 67.3-33.1 26-74.9 41.5-120.3 41.5-7.8 0-15.5-.5-23.1-1.4C62.8 432 113.7 448 168.3 448 346.6 448 444 300.3 444 172.2c0-4.2-.1-8.4-.3-12.5C462.6 146 479 129 492 109.5z" fill="" />
                            </svg>
                        </a>
                    </div>

                    <button className="w-9 h-9 rounded-full border-gray-600 border-2 flex justify-center items-center"
                        onClick={() => setSideBarOpen(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 stroke-black" fill="none" viewBox="0 0 24 24" stroke="none" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>


                <div className="flex flex-col mt-6 divide-y">
                    <button onClick={() => { goTo('#Project'); setSideBarOpen(false) }}
                        className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-black p-3 rounded-md mb-1 hover:text-white hover:bg-cusOrenge">{t('Project')}</button>

                    <button onClick={() => { goTo('#Gallery'); setSideBarOpen(false) }}
                        className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-black p-3 rounded-md mb-1 hover:text-white hover:bg-cusOrenge">{t('Gallery')}</button>

                    <button onClick={() => { goTo('#Native_Artists'); setSideBarOpen(false) }}
                        className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-black p-3 rounded-md mb-1 hover:text-white hover:bg-cusOrenge">{t('Native_Artists')}</button>
                    <button onClick={() => { goTo('#NFT_Artists'); setSideBarOpen(false) }}
                        className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-black p-3 rounded-md mb-1 hover:text-white hover:bg-cusOrenge">{t('NFT_Artists')}</button>

                    <button onClick={() => { goTo('#Curators'); setSideBarOpen(false) }}
                        className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-black p-3 rounded-md mb-1 hover:text-white hover:bg-cusOrenge">{t('Curators')}</button>

                    <button onClick={() => { goTo('#Partners'); setSideBarOpen(false) }}
                        className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-black p-3 rounded-md mb-1 hover:text-white hover:bg-cusOrenge">{t('Partners')}</button>
                </div>
            </div>




        </div>
    );
}