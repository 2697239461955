import Carmen_Velasco from "../Assets/Images/curatorComitee/1. Carmen Velasco.jpg";
import Gustavo_Solmott from "../Assets/Images/curatorComitee/2. Gustavo Solmott.jpg";
import Hugo_Olivares from "../Assets/Images/curatorComitee/3. Hugo Olivares.jpg";
import Susana_Gonzales_Murillo from "../Assets/Images/curatorComitee/4. Susana Gonzales Murillo.jpg";
import Fernando_Allen from "../Assets/Images/curatorComitee/5. Fernando Allen.jpg";
import Lillyan_Garcia from "../Assets/Images/curatorComitee/6. Lillyan Garcia.jpg";
import Marlise_Ilhesca_Jozami from "../Assets/Images/curatorComitee/7. Marlise Ilhesca Jozami.jpg";
import Paula_Marra from "../Assets/Images/curatorComitee/8. Paula Marra.jpg";



export const curators_data = [
    { id: 1, name: "Carmen Velasco", photo: Carmen_Velasco, },
    { id: 2, name: "Gustavo Solmott", photo: Gustavo_Solmott, },
    { id: 3, name: "Hugo Olivares", photo: Hugo_Olivares, },
    { id: 4, name: "Susana Gonzales Murillo", photo: Susana_Gonzales_Murillo, },
    { id: 5, name: "Fernando Allen", photo: Fernando_Allen, },
    { id: 6, name: "Lillyan Garcia", photo: Lillyan_Garcia, },
    { id: 7, name: "Marlise Ilhesca Jozami", photo: Marlise_Ilhesca_Jozami, },
    { id: 8, name: "Paula Marra", photo: Paula_Marra, },
]





















