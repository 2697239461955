import * as React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import { top_section_slider_data } from '../../../Data/top_section_slider_data';

import logo from '../../../Assets/Images/logo.png';
import waveBg from '../../../Assets/Images/Vector.png';

import './TopSection.scss';
import { useTranslation } from 'react-i18next';
export interface ITopSectionProps {
}




export function TopSection(props: ITopSectionProps) {
    const { t } = useTranslation();

    const We_present_you = t('We_present_you_th__', { returnObjects: true, });


    return (
        <div className="h-[1000px] sm:h-[720px] lg:h-[800px] xl:h-[870px] mxl:h-[900px] 2xl:h-[1000px] overflow-hidden relative w-full top_section_box -mt-[103px] z-0">
            <div className="bg-black opacity-30 bg-blend-hue absolute inset-0 z-[-1]"></div>


            <div className="container mx-auto w-[79%] h-[90%] mt-auto grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-3 lg:gap-8 mxl:gap-16 2xl:gap-24 
            justify-start sm:justify-between items-start sm:items-center pt-28 sm:pt-0">

                <div className="flex flex-col justify-center items-start h-full w-full">
                    <div className="w-16 md:w-20 lg:w-28 mxl:w-32 2xl:w-36 h-16 md:h-20 lg:h-28 mxl:h-32 2xl:h-36 bg-white">
                        <img src={logo} alt="" className="w-full h-full" />
                    </div>

                    <div className="w-full mt-4 text-left">
                        <h4 className="text-sm md:text-base lg:text-xl mxl:text-2xl 2xl:text-3xl text-gray-50">{We_present_you[0]} <span className="text-[#ffa601] font-black">{We_present_you[1]}</span> {We_present_you[2]}</h4>
                        <br />
                        <p className="text-2xs md:text-xs lg:text-sm xl:text-base 2xl:text-lg text-gray-200" style={{ whiteSpace: 'pre-line' }}>
                            {t('Native_artists_f')}
                        </p>
                    </div>
                </div>


                <div className="relative h-[80%] sm:h-[50%] md:h-[52%] lg:h-[60%] xl:h-[63%] mxl:h-[65%] 3xl:h-[70%]">
                    <div className="absolute -top-6 -right-16 z-[-1] opacity-60">
                        <svg width={142} height={123} viewBox="0 0 142 123" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M141.4 61.8H71.2L36.1 1H106.3L141.4 61.8Z" stroke="#ffffff60" strokeMiterlimit={10} strokeLinejoin="round" />
                            <path d="M141.4 61.8H71.2L36.1 122.5H106.3L141.4 61.8Z" stroke="#ffffff60" strokeMiterlimit={10} strokeLinejoin="round" />
                            <path d="M50.5 25.8H92L112.8 61.8L92 97.7H50.5L29.7 61.8L50.5 25.8Z" fill="#FC7F4F" />
                            <path d="M36.1 1H106.3L141.4 61.8L106.3 122.5H36.1L1 61.8L36.1 1Z" stroke="#ffffff60" strokeMiterlimit={10} strokeLinejoin="round" />
                            <path d="M106.3 1L71.2 61.8L106.3 122.5L141.4 61.8L106.3 1Z" stroke="#ffffff60" strokeMiterlimit={10} strokeLinejoin="round" />
                            <path d="M71.2 61.8H1L36.1 122.5H106.3L71.2 61.8Z" stroke="#ffffff60" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>

                    <svg className="w-24 opacity-10 absolute" viewBox="0 0 121 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M60.1 114.2C28.9 114.2 3.39999 88.8 3.39999 57.5C3.39999 26.2 28.8 0.799999 60.1 0.799999C91.3 0.799999 116.8 26.2 116.8 57.5C116.8 88.8 91.4 114.2 60.1 114.2ZM60.1 115.2C91.9 115.2 117.7 89.4 117.7 57.6C117.7 25.8 91.9 0 60.1 0C28.3 0 2.5 25.8 2.5 57.6C2.5 89.4 28.3 115.2 60.1 115.2Z" fill="#FC7F4F" />
                        <path d="M60.1 99.1C37.2 99.1 18.6 80.5 18.6 57.6C18.6 34.7 37.2 16.1 60.1 16.1C83 16.1 101.6 34.7 101.6 57.6C101.6 80.5 83.1 99.1 60.1 99.1ZM60.1 100.1C83.6 100.1 102.7 81 102.7 57.5C102.7 34 83.6 14.9 60.1 14.9C36.6 14.9 17.5 34 17.5 57.5C17.5 81 36.7 100.1 60.1 100.1Z" fill="#FC7F4F" />
                        <path d="M60.1 97C38.3 97 20.7 79.4 20.7 57.6H0C0 90.8 26.9 117.7 60.1 117.7C93.3 117.6 120.2 90.7 120.2 57.6H99.5C99.5 79.3 81.9 97 60.1 97Z" fill="#FC7F4F" />
                    </svg>

                    <div className="mt-6 w-full sm:w-[250px] md:w-[260px] lg:w-[350px] xl:w-[390px] mxl:w-[420px] 2xl:w-[450px] 3xl:w-[500px] h-full ml-auto overflow-hidden shadow-2xl">
                        <Swiper
                            speed={2000}
                            effect="fade"
                            loop={true}
                            autoplay={true}
                            modules={[EffectFade, Autoplay]}
                            className="mySwiper h-full w-full"
                        >
                            {top_section_slider_data.map(slider =>
                                <SwiperSlide key={slider.id}>
                                    <div className="h-full object-cover relative">
                                        <img src={slider.src} alt="" className="h-full object-fill w-full" />
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
            </div>





            <svg className="w-36 opacity-20 absolute -top-10 -left-10 rotate-45" viewBox="0 0 121 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M60.1 114.2C28.9 114.2 3.39999 88.8 3.39999 57.5C3.39999 26.2 28.8 0.799999 60.1 0.799999C91.3 0.799999 116.8 26.2 116.8 57.5C116.8 88.8 91.4 114.2 60.1 114.2ZM60.1 115.2C91.9 115.2 117.7 89.4 117.7 57.6C117.7 25.8 91.9 0 60.1 0C28.3 0 2.5 25.8 2.5 57.6C2.5 89.4 28.3 115.2 60.1 115.2Z" fill="#FC7F4F" />
                <path d="M60.1 99.1C37.2 99.1 18.6 80.5 18.6 57.6C18.6 34.7 37.2 16.1 60.1 16.1C83 16.1 101.6 34.7 101.6 57.6C101.6 80.5 83.1 99.1 60.1 99.1ZM60.1 100.1C83.6 100.1 102.7 81 102.7 57.5C102.7 34 83.6 14.9 60.1 14.9C36.6 14.9 17.5 34 17.5 57.5C17.5 81 36.7 100.1 60.1 100.1Z" fill="#FC7F4F" />
                <path d="M60.1 97C38.3 97 20.7 79.4 20.7 57.6H0C0 90.8 26.9 117.7 60.1 117.7C93.3 117.6 120.2 90.7 120.2 57.6H99.5C99.5 79.3 81.9 97 60.1 97Z" fill="#FC7F4F" />
            </svg>

            <svg className="absolute top-1/3 -left-36 w-[300px] opacity-10" viewBox="0 0 117 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M58.6191 117C58.3809 117 58.1426 117 57.9043 116.762L0.23829 59.184C9.0545e-07 58.9461 0 58.7082 0 58.4703C0 58.2324 9.0545e-07 57.9945 0.23829 57.7565L57.9043 0.178444C58.1426 -0.0594815 58.8574 -0.0594815 59.0957 0.178444L116.762 57.7565C117 57.9945 117 58.2324 117 58.4703C117 58.7082 117 58.9461 116.762 59.184L68.8656 107.007C68.6273 107.245 67.9124 107.245 67.6741 107.007L19.778 59.184C19.5397 58.9461 19.5397 58.2323 19.778 57.9944L57.9043 19.9263C58.1426 19.6883 58.8574 19.6883 59.0957 19.9263L97.222 57.9944C97.4603 58.2323 97.4603 58.9461 97.222 59.184L68.6273 87.7351C68.389 87.9731 67.6741 87.9731 67.4358 87.7351L57.9043 78.2181L39.0794 59.422C38.8411 59.1841 38.8411 58.4703 39.0794 58.2323L57.9043 39.4362C58.1426 39.1983 58.8574 39.1983 59.0957 39.4362L77.9206 58.2323C78.1589 58.4703 78.1589 59.1841 77.9206 59.422L59.5723 77.7422L67.9124 86.0696L95.3157 58.4703L58.6191 21.8297L21.9226 58.4703L68.6273 105.104L115.094 58.4703L58.6191 2.08182L2.14461 58.4703L59.0957 115.335C59.334 115.573 59.334 116.286 59.0957 116.524C59.0957 116.762 58.8574 117 58.6191 117ZM40.9857 58.4703L58.6191 76.0769L76.2525 58.4703L58.6191 40.8638L40.9857 58.4703Z" fill="#FC7F4F" />
            </svg>

            <div className="absolute -bottom-16 w-full h-44">
                <img src={waveBg} alt="" className="w-full h-full" style={{ filter: 'drop-shadow(0px 0px 60px #00000050)' }} />
            </div>
        </div>
    );
}
