import sliderImage1 from '../Assets/Images/topSlider/1.jpg';
import sliderImage2 from '../Assets/Images/topSlider/2.jpg';
import sliderImage3 from '../Assets/Images/topSlider/3.jpg';
import sliderImage4 from '../Assets/Images/topSlider/4.jpg';



export const top_section_slider_data = [
    {
        id: 1,
        src: sliderImage1,
    },
    {
        id: 2,
        src: sliderImage2,
    },
    {
        id: 3,
        src: sliderImage3,
    },
    {
        id: 4,
        src: sliderImage4,
    },
]