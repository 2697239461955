import artGalleryPhoto1 from '../Assets/Images/artGallery/1.jpg';
import artGalleryPhoto2 from '../Assets/Images/artGallery/2.jpg';
import artGalleryPhoto3 from '../Assets/Images/artGallery/3.jpg';
import artGalleryPhoto4 from '../Assets/Images/artGallery/4.jpg';
import artGalleryPhoto5 from '../Assets/Images/artGallery/5.jpg';
import artGalleryPhoto6 from '../Assets/Images/artGallery/6.jpg';
import artGalleryPhoto7 from '../Assets/Images/artGallery/7.jpg';
import artGalleryPhoto8 from '../Assets/Images/artGallery/8.jpg';
import artGalleryPhoto9 from '../Assets/Images/artGallery/9.jpg';
import artGalleryPhoto10 from '../Assets/Images/artGallery/10.jpg';
import artGalleryPhoto11 from '../Assets/Images/artGallery/11.jpg';
import artGalleryPhoto12 from '../Assets/Images/artGallery/12.jpg';
import artGalleryPhoto13 from '../Assets/Images/artGallery/13.jpg';
import artGalleryPhoto15 from '../Assets/Images/artGallery/15.jpg';

// videos
import artGallery_1_video from '../Assets/Images/artGallery/video/1_video.mp4';
import artGallery_2_video from '../Assets/Images/artGallery/video/2_video.mp4';
import artGallery_3_video from '../Assets/Images/artGallery/video/3_video.mp4';
import artGallery_4_video from '../Assets/Images/artGallery/video/4_video.mp4';
import artGallery_8_video from '../Assets/Images/artGallery/video/8_video.mp4';
import artGallery_9_video from '../Assets/Images/artGallery/video/9_video.mp4';
import artGallery_10_video from '../Assets/Images/artGallery/video/10_video.mp4';
import artGallery_14_video from '../Assets/Images/artGallery/video/14_video.mp4';



export const nft_art_gallery_data = [
    {
        id: '0',
        name: 'Tapiz QOM - (QOM Tapestry)',
        coCreatedBy: {
            nativeArtist: 'Petronila Gonzalez',
            nftArtist: 'Mirna Teran',
        },
        description: "t('tree', { returnObjects: true,})",
        details: "The weaving is done on a vertical loom, making knots with the hands in horizontal lines. The use of bright organic dyes, convey the history of the community and its respect for nature.",
        art: artGalleryPhoto1,
        video: artGallery_1_video,
    },
    {
        id: '1',
        name: 'Cesta de Paz (Peace Basket)',
        coCreatedBy: {
            nativeArtist: 'Pablina Marin',
            nftArtist: 'Mirna Teran',
        },
        description: "Inside the basket, Pablina carries the knowledge that her community gave her, the birds represent happiness and peace, and the plants represent the ancestral knowledge that she would like to preserve for the generations ahead.",
        details: "Semi-conical piece interwoven with CHAGUAR fibers, alternated with tight stacks of the same natural material, collected as a traditional practice by women of the communities.",
        art: artGalleryPhoto2,
        video: artGallery_2_video,
    },
    {
        id: '2',
        name: 'Pájaros del impenetrable (Birds from the Impenetrable forest)',
        coCreatedBy: {
            nativeArtist: 'Marta Rubino',
            nftArtist: 'Jimena Dominguez',
        },
        description: "Martha makes the Birds of the Impenetrable with the CHAGUAR plant, found in the Monte. As a traditional practice, before obtaining and using the plant she asks mother earth for permission, Wichi traditions and wisdom run through the leaves of the mythical plant.",
        details: "Birds woven in Chaguar, used since ancient times by the Wichí tribes to craft various elements, such as tools, toys and ornaments.",
        art: artGalleryPhoto3,
        video: artGallery_3_video,
    },
    {
        id: '3',
        name: 'Sevalaq Hombre araña (Sevalaq the Spider Man)',
        coCreatedBy: {
            nativeArtist: 'Ronaldo Unruh',
            nftArtist: 'Jimena Dominguez',
        },
        description: "Selvalaq wears a mask and a particular clothing style that characterizes him, his expressions communicate the steadiness of the Enlhet of the North, his gaze reminds us the strength of the legacy and wisdom that Selvalaq, the spider man, represents.",
        details: "Digital painting created in Photoshop CC by the native artist, later animated by the NFT Artist. The art piece was recently displayed by the Santo Domingo Centre of Excellence for Latin American Research at the British Museum - Artist Residency 2022.",
        art: artGalleryPhoto4,
        video: artGallery_4_video,
    },
    {
        id: '4',
        name: 'Oso Hormiguero (Anteater)',
        coCreatedBy: {
            nativeArtist: 'Valentina Diaz',
            nftArtist: 'Santiago Toro',
        },
        description: "Silence in the middle of the grasslands, Valentina watches the anteater in its natural habitat. She is fascinated by the beauty of the landscape, by the peace surrounding the little animal eating and exploring. It makes you think about life in the wilderness and makes you yearn that freedom.",
        details: "Native art created with a wire structure, synthetic fleece padding, mantle made of sheep's wool, knitted with a straight stitch. \n \n Representation and tribute to the region's anteater.",
        art: artGalleryPhoto5,
    },
    {
        id: '5',
        name: 'Horneros a trabajar (Ovenbirds go to work)',
        coCreatedBy: {
            nativeArtist: 'Estela Juliuz',
            nftArtist: 'Santiago Toro',
        },
        description: "Early in the morning, Estela wakes up because she has to work, she looks out the window and realizes that she's not the only one who has to go out. She listens to a sweet and soft song, looks up at the top of the tree. They are the ovenbirds, also leaving their nest to find sustenance, they make her company and encourage her throughout the day.",
        details: "Pen drawing on paper by the native artist, with different levels of pressure to achieve gray scales, with spaces completely painted in black. \n \n  Representation of nature and animals that are part of the traditions and culture of communities",
        art: artGalleryPhoto6,
    },
    {
        id: '6',
        name: 'YICA (Yica bag)',
        coCreatedBy: {
            nativeArtist: 'Elina Costa',
            nftArtist: 'Monica Fuertes',
        },
        description: "The doll wears the Yica bag as a form of protection, the garment represents containment and safety, she takes refuge from the dangers outside by wearing the garment. Her dreamy face alludes to the Wichí legends and beliefs, the garment rescues the tradition of the use of indigenous fabrics in clothing.",
        details: "Yica (typical bag) woven with a needle on a mobile loom made from Chaguar using the ancient wichí stitch (armadillo ear). \n \n Communities believe that Yica is a garment of protection against the spirits that own the animals of the mountain, which protects them from attacks, for example, a bite from the Yarará snake.",
        art: artGalleryPhoto12,
    },
    {
        id: '7',
        name: 'PALO BORRACHO (Drunken stick tree)',
        coCreatedBy: {
            nativeArtist: 'Doriana Falcon',
            nftArtist: 'Rodrigo Reyes y Monica Fuertes',
        },
        description: "The deterioration of nature by fire and humans can be seen, the drunken stick tree burns and generates particles flying through the air. Birds are represented in the particles and shapes of the art, using a mirror technique, inspired by the origin of the universe.",
        details: "The drawings represent the concern for the unstoppable deforestation and destruction of Gran Chaco ecosystem, the symbolic world of indigenous peoples, in the form of forests and wild animals.",
        art: artGalleryPhoto10,
        video: artGallery_8_video,
    },
    {
        id: '8',
        name: 'Taguás (Taguás)',
        coCreatedBy: {
            nativeArtist: 'Floriberta Fermin',
            nftArtist: 'Rodrigo Reyes',
        },
        description: "This artwork brings the Taguás to the digital world, an endangered species threatened by wildfires and hunters. This tender creatures are fearful of humans and climate change. The slight background sound transports us to their habitat, their peaceful surroundings, you can also see how these creatures are wrapped in small particles of smoke and fire left by the burning of these forests.",
        details: "Drawings by the Native Artist, using a common pen on cardboard, with different levels of pressure to achieve gray scales, or spaces completely painted in black. \n \n It represents the admiration for the Taguás, the forests and the nature that remains in spite of the deforestation of Gran Chaco.",
        art: artGalleryPhoto8,
        video: artGallery_9_video,
    },
    {
        id: '9',
        name: 'Tapiz Qomlec (Qomlec Tapestry)',
        coCreatedBy: {
            nativeArtist: 'Aureliana Gomez',
            nftArtist: 'Rodrigo Reyes',
        },
        description: "Through the technique of generative art we appreciate the dept of native art, breaking down the complex terminated work into particles. The art shows different levels of detail in the knitted element. \n \n This piece carries specific components and design traits from the tapestry, such as the weight of the wool and the natural dye (black tea leafs).",
        details: "Tapestry Intertwined by hand on a vertical loom using wool from Creole sheep sheared by hand, in black and white. \n \n This piece collects the wisdom of a mother, seeking to transmit her inherited skill, leaving it as a legacy and as a mother-daughter bond through art.",
        art: artGalleryPhoto7,
        video: artGallery_10_video,
    },
    {
        id: '10',
        name: "Nengko'o enlhet (We are enlhet)",
        coCreatedBy: {
            nativeArtist: 'Ronaldo Unruh',
        },
        description: "The Northern Enlhet people wore bird feathers on their heads, they used plumage colors and patterns as an identification method, for example when a person from another village came, they easily identified them and welcomed them. \n \n This NFT grants you access to another image created by the author, showing the character with a sad face, to represent the sadness of people that are being stripped of their culture and identity.",
        details: "Digital paint created by the Native Artist using Photoshop CC.",
        art: artGalleryPhoto9,
        aditionalImageSrc: "/documents/11.Unlockable Ronaldo -nengko'o enlhet.png",
    },
    {
        id: '11',
        name: 'Paño de Chaguar (Chaguar Cloth)',
        coCreatedBy: {
            nativeArtist: 'Fermina Gomez',
        },
        description: "With each stitch, the daughter remembers and honors her mother, representing the preservation of her ancestral knowledge and the legacy of a technique that few women know how to perform because of its complexity and the skill required.",
        details: "Weaving with a needle on a mobile loom made from Chaguar using the old Wichí stitch (armadillo ear).",
        art: artGalleryPhoto11,
    },
    {
        id: '12',
        name: "¿Quién es Nengko’o? (Who is Nengko'o?) \n Artificial Intelligence generated NFT.",
        coCreatedBy: {
            nativeArtist: 'Ronaldo Unruh',
            nftArtist: 'Soliman Lopez',
        },
        description: "This piece represents the loss of identity and the frustration caused by artificial expressions void of culture and art. Evolution and technology overlooks native communities and interprets their cultural expressions in a superficial manner. \n \n The text shows the gap between an original native art piece and the technology used to read it, there is still something missing, technology should have a purpose and be meaningful to preserve and nurture culture.",
        details: "The original piece (unlockable content) is a digital painting created in Photoshop by native artist Ronaldo Unruh.",
        art: artGalleryPhoto13,
        aditionalImageSrc: "/documents/Unlockable WhosNengko.png",
    },
    {
        id: '13',
        roadmapNFT: true,
        name: 'CHAGUAR NFT',
        nftArtist: 'Soliman Lopez',
        price: 'EACH CHAGUAR NFT costs: 0.19 ETH excluding gas fees.',
        inspiredBy: "Chaguar threading techniques from Gran Chaco communities.",
        description: "1/1 unique chaguar skeins.",
        details: "44 pieces",
        art: artGalleryPhoto15,
        video: artGallery_14_video,
    },
]