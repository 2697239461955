import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Heading } from '../../Components/Heading/Heading';
import { nft_art_gallery_data } from '../../Data/nft_art_gallery_data';

import bg_elements from '../../Assets/Images/bg_elements.png';
import ScrollToTop from '../../Hooks/ScrollToTop';
import { ConnectWalletModal } from './ConnectWalletModal/ConnectWalletModal';
import { useTranslation } from 'react-i18next';
import { getOneOfOneEngineInfo, mintOneOfOne } from 'Utils/contracts';
import { OneOfOneEngineDetail } from 'Utils/typs';
import { currentNetwork } from 'Utils';
import { useWeb3React } from '@web3-react/core';
import toast from 'react-hot-toast';
import { ChaguarNFT } from './ChaguarNFT/ChaguarNFT';
import { LoadingMarkup } from 'Components/LoadingMarkup/LoadingMarkup';


export interface IArtDetailsProps {

}

export function ArtDetails(props: IArtDetailsProps) {
    const { t } = useTranslation();
    const { artID } = useParams();
    const [nftArtData, setNftArtData] = React.useState<any>(null)
    const [selectedTier, setSelectedTier] = React.useState('')
    const [selectedTierNumber, setSelectedTierNumber] = React.useState(0)
    const [downloadURLs, setDownloadURLs] = React.useState<any>([]);
    const [oneofoneEngine, setOneOfOneEngine] = React.useState<OneOfOneEngineDetail>(null);

    React.useEffect(() => {
        const findClickedNftData = nft_art_gallery_data.find(data => data.id === artID)
        setNftArtData(findClickedNftData)
        getOneOfOneEngineInfo(artID).then((engineInfo: OneOfOneEngineDetail) => {
            setOneOfOneEngine(engineInfo);
        })
    }, [artID])


    React.useEffect(() => {
        if (nftArtData?.aditionalImageSrc) {
            setDownloadURLs([selectedTier, nftArtData?.aditionalImageSrc])
        } else {
            setDownloadURLs([selectedTier])
        }
    }, [nftArtData?.aditionalImageSrc, selectedTier])



    const downloadFiles = (urls: any) => {
        const link = document.createElement('a');
        link.setAttribute('download', '');
        link.style.display = 'none';
        document.body.appendChild(link);

        for (let i = 0; i < urls.length; i++) {
            link.setAttribute('href', urls[i]);
            link.click();
        }
        document.body.removeChild(link);
    }

    const [loginStatus, setLoginStatus] = React.useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    React.useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(currentNetwork);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId]);

    const purchaseTier = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }
        const load_toast_id = toast.loading("Please wait for Mint...");
        try {
            const bSuccess = await mintOneOfOne(chainId, library.getSigner(), account, artID, selectedTierNumber);
            if (bSuccess) {
                toast.success("Mint Success!");
                downloadFiles(downloadURLs);
                setTimeout(() => {
                    getOneOfOneEngineInfo(artID).then((engineInfo: OneOfOneEngineDetail) => {
                        setOneOfOneEngine(engineInfo);
                    })
                }, 2000);
            } else {
                toast.error("Mint Failed!");
            }
        } catch (error) {
            toast.error("Mint Failed!");
        }
        toast.dismiss(load_toast_id);
    }


    return (
        <div className="mt-20 relative pb-44">
            <ScrollToTop />

            <div className="container mx-auto w-[79%]">

                <div className="flex flex-col xsm:flex-row justify-between items-start xsm:items-center">
                    <Heading classes="text-black font-extrabold text-lg md:text-2xl xl:text-3xl 2xl:text-4xl text-left">
                        {t('NFT_ART_DETAILS')}
                    </Heading>
                    <ConnectWalletModal onlyConnect={false} />
                </div>


                {nftArtData && nftArtData.roadmapNFT
                    ? <ChaguarNFT nftArtData={nftArtData} />
                    : !oneofoneEngine
                        ? <div className="w-full py-6 flex justify-center items-center">
                            <LoadingMarkup />
                        </div>
                        : <div className="grid grid-cols-1 md:grid-cols-5 justify-center items-start mt-10 gap-12 md:gap-5 lg:gap-14">

                            {nftArtData?.video
                                ? <div className="md:col-span-2 xl:h-full rounded-xl overflow-hidden hover:shadow-2xl transition-all duration-300">
                                    <video width={320} height={240} controls loop autoPlay className="w-full h-full">
                                        <source src={nftArtData?.video} type="video/mp4" />
                                        <source src={nftArtData?.video} type="video/ogg" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                : <div className="md:col-span-2 xl:h-full rounded-xl overflow-hidden hover:shadow-2xl transition-all duration-300">
                                    <img src={nftArtData?.art} alt="" className="w-full h-full object-cover hover:scale-110 transition-all duration-300" />
                                </div>
                            }



                            <div className="md:col-span-3 text-left">
                                <h1 className="text-lg lg:text-xl mxl:text-2xl 2xl:text-3xl font-extrabold" style={{ whiteSpace: 'pre-line', }}>{
                                    nftArtData?.id === '13'
                                        ? t('13.name')
                                        : nftArtData?.name
                                }</h1>

                                <div className="flex justify-start items-start flex-col sm:flex-row mt-6">
                                    <h5 className="font-extrabold text-base xl:text-lg mxl:text-xl text-cusOrenge">{t('Co-created_by')}:</h5>
                                    <div className="sm:ml-3">
                                        <p className="text-base xl:text-lg 2xl:text-xl font-medium">{nftArtData?.coCreatedBy?.nativeArtist} ({t('Native_Artist______')})</p>
                                        {nftArtData?.coCreatedBy?.nftArtist &&
                                            <p className="text-base xl:text-lg 2xl:text-xl font-medium mt-2">{nftArtData?.coCreatedBy?.nftArtist} ({t('NFT_Artist_____')})</p>
                                        }
                                    </div>
                                </div>

                                <div className="mt-4 xl:mt-6 mxl:mt-8">
                                    <h5 className="font-extrabold text-base xl:text-lg mxl:text-xl text-cusOrenge">{t('Description')}:
                                        <span className="text-sm xl:text-base mxl:text-lg font-normal text-gray-600 ml-2" style={{ whiteSpace: 'pre-line' }}>
                                            {t(`${nftArtData?.id}.description`, { returnObjects: true })}
                                        </span>
                                    </h5>
                                </div>

                                <div className="mt-4 xl:mt-6 mxl:mt-8">
                                    <h5 className="font-extrabold text-base xl:text-lg mxl:text-xl text-cusOrenge">{t('Details')}:
                                        <span className="text-sm xl:text-base mxl:text-lg text-gray-600 font-normal ml-2" style={{ whiteSpace: 'pre-line' }}>
                                            {t(`${nftArtData?.id}.details`, { returnObjects: true })}
                                        </span>
                                    </h5>
                                </div>

                                <h5 className="font-extrabold text-base xl:text-lg mxl:text-xl text-cusOrenge mt-4 xl:mt-6 mxl:mt-8">{t('Choose_Tier')}:</h5>
                                {oneofoneEngine.isMinted
                                    ?
                                    <div className="text-white font-bold transition-all duration-300 select-none
                                        border-4 border-cusOrenge bg-cusOrenge text-center px-1 py-2 rounded-xl cursor-not-allowed
                                        text-xs mxl:text-sm 2xl:text-base max-w-xs mt-2">{
                                            oneofoneEngine.tierNumber === 1
                                                ? t('Tier_1_Equip_a_')
                                                : oneofoneEngine.tierNumber === 2
                                                    ? t('Tier_2_Receive')
                                                    : oneofoneEngine.tierNumber === 3 && t('Tier_3_Travel_to')
                                        }
                                    </div>

                                    : <div className="flex justify-between items-center flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-4 mt-2">
                                        <label className="w-full" onClick={() => { setSelectedTier('/documents/TIER 1.pdf'); setSelectedTierNumber(1) }}>
                                            <input type="radio" name="chooseTier" id="tier1" className="peer hidden" />
                                            <p className="peer-checked:text-white font-bold cursor-pointer transition-all duration-300 select-none
                                        border-4 peer-checked:border-cusOrenge peer-checked:bg-cusOrenge text-center px-1 py-2 rounded-xl
                                        text-xs mxl:text-sm 2xl:text-base">{t('Tier_1_Equip_a_')}</p>
                                        </label>
                                        <label className="w-full" onClick={() => { setSelectedTier('/documents/TIER 2.pdf'); setSelectedTierNumber(2) }}>
                                            <input type="radio" name="chooseTier" id="tier2" className="peer hidden" />
                                            <p className="peer-checked:text-white font-bold cursor-pointer transition-all duration-300 select-none
                                        border-4 peer-checked:border-cusOrenge peer-checked:bg-cusOrenge text-center px-1 py-2 rounded-xl
                                        text-xs mxl:text-sm 2xl:text-base">{t('Tier_2_Receive')}</p>
                                        </label>
                                        <label className="w-full" onClick={() => { setSelectedTier('/documents/TIER 3.pdf');; setSelectedTierNumber(3) }}>
                                            <input type="radio" name="chooseTier" id="tier3" className="peer hidden" />
                                            <p className="peer-checked:text-white font-bold cursor-pointer transition-all duration-300 select-none
                                        border-4 peer-checked:border-cusOrenge peer-checked:bg-cusOrenge text-center px-1 py-2 rounded-xl
                                        text-xs mxl:text-sm 2xl:text-base">{t('Tier_3_Travel_to')}</p>
                                        </label>
                                    </div>
                                }



                                <div className="flex justify-end items-center mt-5 lg:mt-7 xl:mt-10">
                                    {oneofoneEngine.isMinted
                                        ? <button
                                            title="Sold Out"
                                            className="text-white font-bold bg-cusOrenge opacity-40 select-none cursor-not-allowed px-16 xl:px-20 py-2 xl:py-3 rounded-xl ml-auto">
                                            Sold Out
                                        </button>

                                        : selectedTier === ''
                                            ? <button
                                                title="Please Choose a Tier"
                                                className="text-white font-bold bg-cusOrenge opacity-40 select-none cursor-not-allowed px-16 xl:px-20 py-2 xl:py-3 rounded-xl ml-auto">
                                                {t('buy_btn')}
                                            </button>
                                            : <button onClick={() => purchaseTier()} className="text-white font-bold bg-cusOrenge hover:bg-cusRed transition-all duration-300
                                        hover:tracking-widest px-16 xl:px-20 py-2 xl:py-3 rounded-xl ml-auto">
                                                {t('buy_btn')}
                                            </button>}
                                </div>
                            </div>
                        </div>
                }
            </div>




            <img src={bg_elements} alt="" className="absolute z-[-1] w-full h-auto inset-0 opacity-40" />
        </div>
    );
}
