import Petronila_Gonzales from "../Assets/Images/NativeArtist/1. Petronila Gonzales.png";
import Pablina_Marin from "../Assets/Images/NativeArtist/2. Pablina Marin.jpg";
import Marta_Rubino from "../Assets/Images/NativeArtist/3. Marta Rubino .jpg";
import Ronaldo_Unruh from "../Assets/Images/NativeArtist/4. Ronaldo Unruh.jpg";
import Valentina_Diaz from "../Assets/Images/NativeArtist/5. Valentina Diaz.jpg";
import Estela_Juliuz from "../Assets/Images/NativeArtist/6. Estela Juliuz.jpg";
import Elina_Costa from "../Assets/Images/NativeArtist/7. Elina Costa .jpg";
import Doriana_Falcon from "../Assets/Images/NativeArtist/8. Doriana Falcon.jpg";
import Floriberta_Fermin from "../Assets/Images/NativeArtist/9. Floriberta Fermin.jpg";
import Guillermina_Gomez from "../Assets/Images/NativeArtist/10. Guillermina Gomez.jpg";
import Fermina_Gomez from "../Assets/Images/NativeArtist/11. Fermina Gomez.jpg";


export const native_artist_data = [
    { id: 1, name: "Petronila Gonzales", photo: Petronila_Gonzales, },
    { id: 2, name: "Pablina Marin", photo: Pablina_Marin, },
    { id: 3, name: "Marta Rubino", photo: Marta_Rubino, },
    { id: 4, name: "Ronaldo Unruh", photo: Ronaldo_Unruh, },
    { id: 5, name: "Valentina Diaz", photo: Valentina_Diaz, },
    { id: 6, name: "Estela Juliuz", photo: Estela_Juliuz, },
    { id: 7, name: "Elina Costa", photo: Elina_Costa, },
    { id: 8, name: "Doriana Falcon", photo: Doriana_Falcon, },
    { id: 9, name: "Floriberta Fermin", photo: Floriberta_Fermin, },
    { id: 10, name: "Guillermina Gomez", photo: Guillermina_Gomez, },
    { id: 11, name: "Fermina Gomez", photo: Fermina_Gomez, },
]