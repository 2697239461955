import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../../Components/Heading/Heading';
import { nft_artist_data } from '../../../Data/nft_artist_data';

export interface INftArtistProps {
}

export function NftArtist(props: INftArtistProps) {
    const { t } = useTranslation();


    return (
        <div className="mt-44 container mx-auto w-[79%]" id="NFT_Artists">
            <div className="mx-auto w-fit">
                <Heading classes="text-black font-extrabold text-xl md:text-2xl xl:text-3xl 2xl:text-4xl text-left">
                    {t('Meet_our_NFT_Artists', { returnObjects: true })[0]} <span className="text-cusOrenge">{t('Meet_our_NFT_Artists', { returnObjects: true })[1]}</span>
                </Heading>
            </div>



            <div className="mt-16 flex flex-wrap justify-center items-center">
                {nft_artist_data.map(artist =>
                    <div className="flex-[0_0_calc(100%-20px)] sm:flex-[0_0_calc(50%-20px)] md:flex-[0_0_calc(30%-20px)] xl:flex-[0_0_calc(25%-20px)] 2xl:flex-[0_0_calc(20%-20px)]  mb-8 mx-5" key={artist.id}>
                        <div className="rounded-xl overflow-hidden">
                            <img src={artist.photo} alt="" className="w-full h-full" />
                        </div>
                        <h5 className="mt-2 font-bold text-base sm:text-sm lg:text-base">{artist.name}</h5>
                    </div>
                )}
            </div>


        </div>
    );
}
