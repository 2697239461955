
import log_avina from "../Assets/Images/partners/log avina.png";
import log_bid_lab from "../Assets/Images/partners/log bid lab.png";
import log_grupo_sunu from "../Assets/Images/partners/log grupo sunu.png";
import log_nativa from "../Assets/Images/partners/log nativa.png";
import logo_caminnos from "../Assets/Images/partners/logo caminnos.png";
import logo_fundacion_gran_chaco from "../Assets/Images/partners/logo fundacion gran chaco.png";
import logo_wtt from "../Assets/Images/partners/logo wtt.png";
import LOGO_NANUM from "../Assets/Images/partners/LOGO NANUM.png";
import Logo_niftydreams3 from "../Assets/Images/partners/Logo niftydreams3.png";

export const partners_data = [
    { id: 1, logo: log_avina },
    { id: 2, logo: log_bid_lab },
    { id: 3, logo: log_grupo_sunu },
    { id: 4, logo: log_nativa },
    { id: 6, logo: logo_caminnos },
    { id: 7, logo: logo_fundacion_gran_chaco },
    { id: 8, logo: logo_wtt },
    { id: 9, logo: LOGO_NANUM },
    { id: 10, logo: Logo_niftydreams3 },
]









