import * as React from 'react';
import { useTranslation } from 'react-i18next';

import bg_elements from '../../../Assets/Images/bg_elements.png';
import makeAnImpactByWoningNFT_image from '../../../Assets/Images/makeAnImpactByWoningNFT.jpg';
import makeAnImpactByWoningNFT_image2 from '../../../Assets/Images/makeAnImpactByWoningNFT2.jpg';

export interface IOwningArftAccessProps {
}

export function OwningArftAccess(props: IOwningArftAccessProps) {
    const { t } = useTranslation();
    const Choose_your_nft_art = t('Choose_your_nft_art', { returnObjects: true })



    return (
        <div className="mt-16 relative" id="Project">

            <div className="container mx-auto w-[79%]">
                <h1 className="text-xl md:text-2xl xl:text-3xl 2xl:text-4xl font-black text-black">{t('MAKE_AN_IMPACT_B', { returnObjects: true })[0]} <br />
                    <span className="text-cusOrenge">{t('MAKE_AN_IMPACT_B', { returnObjects: true })[1]}</span>
                </h1>


                <div className="mt-16 2xl:mt-20">
                    <div className="grid grid-cols-1 md:grid-cols-5 justify-center items-center gap-10 2xl:gap-20 3xl:gap-28">
                        <div className="w-full md:col-span-2 h-full">
                            <img src={makeAnImpactByWoningNFT_image} alt="" className="w-full 2xl:h-full object-cover" />
                        </div>

                        <div className="text-left md:col-span-3">
                            <h2 className="text-lg lg:text-2xl 3xl:text-3xl font-extrabold w-10/12">
                                {Choose_your_nft_art[0]} <span className="text-cusOrenge uppercase">{Choose_your_nft_art[1]} </span>
                                {Choose_your_nft_art[2]} <span className="text-cusOrenge uppercase">{Choose_your_nft_art[3]}</span>
                            </h2>
                            <h5 className="lg:text-xl mt-6 w-10/12">
                                {t('After_choosing_your_NFT')}
                            </h5>


                            <div className="flex md:hidden mxl:flex flex-wrap justify-center items-center mt-6 2xl:mt-10">
                                <div className="text-center border border-gray-200 hover:shadow-lg shadow-sm transition-all duration-300 rounded-xl p-5 mx-4 mb-8
                                flex-[0_0_calc(100%-20px)] mxl:flex-[0_0_calc(48%-20px)] 2xl:flex-[0_0_calc(48%-20px)] 3xl:flex-[0_0_calc(45%-20px)]">
                                    <p className="text-xl text-cusOrenge font-extrabold mr-4">{t('TIER_1', { returnObjects: true })[0]}</p>
                                    <p className="text-lg text-[#021558e3] font-extrabold mr-4">{t('TIER_1', { returnObjects: true })[1]}</p>
                                    <p className="text-sm text-gray-600 flex-1 mt-1">
                                        {t('TIER_1', { returnObjects: true })[2]}
                                    </p>
                                </div>

                                <div className="text-center border border-gray-200 hover:shadow-lg shadow-sm transition-all duration-300 rounded-xl p-5 mx-4 mb-8
                                flex-[0_0_calc(100%-20px)] mxl:flex-[0_0_calc(48%-20px)] 2xl:flex-[0_0_calc(48%-20px)] 3xl:flex-[0_0_calc(45%-20px)]">
                                    <p className="text-xl text-cusOrenge font-extrabold mr-4">{t('TIER_2', { returnObjects: true })[0]}</p>
                                    <p className="text-lg text-[#021558e3] font-extrabold mr-4">{t('TIER_2', { returnObjects: true })[1]}</p>
                                    <p className="text-sm text-gray-600 flex-1 mt-1">
                                        {t('TIER_2', { returnObjects: true })[2]}
                                    </p>
                                </div>

                                <div className="text-center border border-gray-200 hover:shadow-lg shadow-sm transition-all duration-300 rounded-xl p-5 mx-4
                                flex-[0_0_calc(100%-20px)] mxl:flex-[0_0_calc(100%-20px)] 2xl:flex-[0_0_calc(85%-20px)] 3xl:flex-[0_0_calc(75%-20px)]">
                                    <p className="text-xl text-cusOrenge font-extrabold mr-4">{t('TIER_3', { returnObjects: true })[0]}</p>
                                    <p className="text-lg text-[#021558e3] font-extrabold mr-4">{t('TIER_3', { returnObjects: true })[1]}</p>
                                    <p className="text-sm text-gray-600 flex-1 mt-1">
                                        {t('TIER_3', { returnObjects: true })[2]}
                                    </p>
                                </div>
                            </div>

                            <p className="text-sm lg:text-base xl:text-lg mxl:text-xl font-extrabold text-gray-600 mt-5">{t('After_covering_expenses_r')}</p>
                        </div>
                    </div>


                    <div className="hidden md:block mxl:hidden">
                        <div className="grid grid-cols-7 justify-center items-center gap-6 mt-6 2xl:mt-10">
                            <div className="col-span-2 text-center border border-gray-200 hover:shadow-lg shadow-sm transition-all duration-300 rounded-xl p-5 h-full">
                                <p className="text-xl text-cusOrenge font-extrabold mr-4">{t('TIER_1', { returnObjects: true })[0]}</p>
                                <p className="text-lg text-[#021558e3] font-extrabold mr-4">{t('TIER_1', { returnObjects: true })[1]}</p>
                                <p className="text-sm text-gray-600 flex-1 mt-1">
                                    {t('TIER_1', { returnObjects: true })[2]}
                                </p>
                            </div>

                            <div className="col-span-2 text-center border border-gray-200 hover:shadow-lg shadow-sm transition-all duration-300 rounded-xl p-5 h-full">
                                <p className="text-xl text-cusOrenge font-extrabold mr-4">{t('TIER_2', { returnObjects: true })[0]}</p>
                                <p className="text-lg text-[#021558e3] font-extrabold mr-4">{t('TIER_2', { returnObjects: true })[1]}</p>
                                <p className="text-sm text-gray-600 flex-1 mt-1">
                                    {t('TIER_2', { returnObjects: true })[2]}
                                </p>
                            </div>

                            <div className="text-center border border-gray-200 hover:shadow-lg shadow-sm transition-all duration-300 rounded-xl p-5 h-full col-span-3">
                                <p className="text-xl text-cusOrenge font-extrabold mr-4">{t('TIER_3', { returnObjects: true })[0]}</p>
                                <p className="text-lg text-[#021558e3] font-extrabold mr-4">{t('TIER_3', { returnObjects: true })[1]}</p>
                                <p className="text-sm text-gray-600 flex-1 mt-1">
                                    {t('TIER_3', { returnObjects: true })[2]}
                                </p>
                            </div>
                        </div>

                        <p className="text-sm lg:text-base xl:text-lg mxl:text-xl font-extrabold text-gray-600 mt-5">{t('After_covering_expenses_r')}</p>
                    </div>






                    <div className="grid grid-cols-1 md:grid-cols-5 justify-center items-center gap-10 2xl:gap-20 3xl:gap-28 mt-28">
                        <div className="text-left md:col-span-3">

                            <div className="text-center border border-gray-200 hover:shadow-lg shadow-sm transition-all duration-300 rounded-xl p-5 mx-auto 
                            mxl:w-9/12 2xl:w-8/12 3xl:w-7/12 mb-8">
                                <p className="text-xl text-cusOrenge font-extrabold mr-4">{t('TIER_4', { returnObjects: true })[0]}</p>
                                <p className="text-lg text-[#021558e3] font-extrabold mr-4">{t('TIER_4', { returnObjects: true })[1]}</p>
                                <p className="text-sm text-gray-600 flex-1 mt-1">
                                    {t('TIER_4', { returnObjects: true })[2]}
                                </p>
                            </div>


                            <h2 className="text-lg lg:text-2xl 3xl:text-3xl leading-8 mxl:leading-10 font-extrabold w-full xl:w-11/12">
                                {t('This_additional_series')}
                            </h2>

                            <div className="flex justify-start items-start mt-8">
                                <p className="text-sm lg:text-base xl:text-lg mxl:text-xl text-cusOrenge font-extrabold mr-4">1.</p>
                                <p className="text-sm lg:text-base xl:text-lg mxl:text-xl text-gray-600 flex-1">
                                    {t('collectibles', { returnObjects: true })[0]}
                                </p>
                            </div>
                            <div className="flex justify-start items-start mt-3">
                                <p className="text-sm lg:text-base xl:text-lg mxl:text-xl text-cusOrenge font-extrabold mr-4">2.</p>
                                <p className="text-sm lg:text-base xl:text-lg mxl:text-xl text-gray-600 flex-1">
                                    {t('collectibles', { returnObjects: true })[1]}
                                </p>
                            </div>
                            <div className="flex justify-start items-start mt-3 w-10/12">
                                <p className="text-sm lg:text-base xl:text-lg mxl:text-xl text-cusOrenge font-extrabold mr-4">3.</p>
                                <p className="text-sm lg:text-base xl:text-lg mxl:text-xl text-gray-600 flex-1">
                                    {t('collectibles', { returnObjects: true })[2]}
                                </p>
                            </div>
                        </div>

                        <div className="w-full md:col-span-2 h-full">
                            <img src={makeAnImpactByWoningNFT_image2} alt="" className="w-full lg:h-full object-cover" />
                        </div>
                    </div>


                </div>
            </div>





            <img src={bg_elements} alt="" className="absolute z-[-1] w-full h-auto inset-0 opacity-40" />
        </div>
    );
}
