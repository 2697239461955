import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ArtDetails } from './Pages/ArtDetails/ArtDetails';
import { Home } from './Pages/Home/Home';

export interface IAllRoutesProps {
}

export function AllRoutes(props: IAllRoutesProps) {


    return (
        <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/nftArtDetails/:artID" element={<ArtDetails />} />
        </Routes>
    );
}
