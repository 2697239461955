import * as React from 'react';

export interface IHeadingProps {
    classes: string,
    children: any,
}

export function Heading({ classes, children }: IHeadingProps) {

    return (
        <div className="flex justify-start items-center">

            <svg className="w-3 lg:w-4 2xl:w-5 -mb-0 md:-mb-1.5 mr-2" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.7 4.39999L8.79999 2L13 4.39999V9.09998L8.79999 11.5L4.7 9.09998V4.39999Z" fill="#FC7F4F" />
                <path d="M4.24552e-09 4C4.24552e-09 2.90323 0.903228 2 2 2C3.09677 2 4 2.90323 4 4C4 5.09677 3.09677 6 2 6C0.903228 6 4.24552e-09 5.09677 4.24552e-09 4Z" fill="#FC7F4F" />
                <path d="M3.71737 1.30182C3.65869 0.705276 4.10164 0.165678 4.69819 0.107C5.29473 0.0483228 5.83433 0.491276 5.89301 1.08782C5.95169 1.68437 5.50874 2.22397 4.91219 2.28265C4.31564 2.34132 3.77604 1.89837 3.71737 1.30182Z" fill="#FC7F4F" />
            </svg>

            <h1 className={classes}>{children}</h1>

            <svg className="w-3 lg:w-4 2xl:w-5 -mb-0 md:-mb-2 ml-2" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.3 4.39999L4.20001 2L0 4.39999V9.09998L4.20001 11.5L8.3 9.09998V4.39999Z" fill="#FC7F4F" />
                <path d="M13 4C13 2.90323 12.0968 2 11 2C9.90323 2 9 2.90323 9 4C9 5.09677 9.90323 6 11 6C12.0968 6 13 5.09677 13 4Z" fill="#FC7F4F" />
                <path d="M9.28263 1.30182C9.34131 0.705276 8.89836 0.165678 8.30181 0.107C7.70527 0.0483228 7.16567 0.491276 7.10699 1.08782C7.04831 1.68437 7.49126 2.22397 8.08781 2.28265C8.68436 2.34132 9.22396 1.89837 9.28263 1.30182Z" fill="#FC7F4F" />
            </svg>
        </div>
    );
}
