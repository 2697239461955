import * as React from 'react';
import { Curators } from './Curators/Curators';
import { NativeArtist } from './NativeArtist/NativeArtist';
import { NftArtGallery } from './NftArtGallery/NftArtGallery';
import { NftArtist } from './NftArtist/NftArtist';
import { OwningArftAccess } from './OwningArftAccess/OwningArftAccess';
import { Partners } from './Partners/Partners';
import { TopSection } from './TopSection/TopSection';

import logo from '../../Assets/Images/logo.png';
import { useTranslation } from 'react-i18next';



export interface IHomeProps {
}

export function Home(props: IHomeProps) {
    const { t } = useTranslation();


    return (
        <div>
            <TopSection />
            <OwningArftAccess />
            <NftArtGallery />
            <NativeArtist />
            <NftArtist />
            <Curators />
            <Partners />

            <div className="pt-44 pb-10">
                <img src={logo} alt="" className="w-40 mx-auto" />
                <p className="font-semibold text-xs sm:text-sm lg:text-base">{t('Copyright_©_2022')}</p>
            </div>
        </div>
    );
}
