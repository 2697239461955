import * as React from 'react';
import { Heading } from '../../../Components/Heading/Heading';

import chaguarPhoto from '../../../Assets/Images/chaguar1.jpg';
import roadmapIcon from '../../../Assets/Images/roadmapIcon.png';
import { useTranslation } from 'react-i18next';
import { CommonEngineDetail } from 'Utils/typs';
import { getCommonEngineInfo, mintCommon } from 'Utils/contracts';
import { useWeb3React } from '@web3-react/core';
import { currentNetwork } from 'Utils';
import toast from 'react-hot-toast';
import { ConnectWalletModal } from '../ConnectWalletModal/ConnectWalletModal';

export interface IChaguarNFTProps {
    nftArtData: any,
}

export function ChaguarNFT({ nftArtData }: IChaguarNFTProps) {
    const { t } = useTranslation();
    const [mintQuantity, setMintQuantity] = React.useState(0)
    const [commonEngine, setCommonEngine] = React.useState<CommonEngineDetail>(null);

    React.useEffect(() => {
        getCommonEngineInfo().then((engineInfo: CommonEngineDetail) => {
            setCommonEngine(engineInfo);
        })
    }, [])

    const handleQuantityIncrease = () => {
        if (mintQuantity < 5) {
            setMintQuantity((prevValue) => prevValue + 1)
        } else {
            return
        }
    }

    const handleQuantityDecrease = () => {
        if (mintQuantity > 1) {
            setMintQuantity((prevValue) => prevValue - 1)
        } else {
            return
        }
    }

    const [loginStatus, setLoginStatus] = React.useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    React.useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(currentNetwork);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId]);

    const purchaseTierX = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }
        const load_toast_id = toast.loading("Please wait for Mint...");
        try {
            const bSuccess = await mintCommon(chainId, library.getSigner(), account, mintQuantity);
            if (bSuccess) {
                toast.success("Mint Success!");

                setTimeout(() => {
                    getCommonEngineInfo().then((engineInfo: CommonEngineDetail) => {
                        setCommonEngine(engineInfo);
                    })
                }, 2000);
            } else {
                toast.error("Mint Failed!");
            }
        } catch (error) {
            toast.error("Mint Failed!");
        }
        toast.dismiss(load_toast_id);
    }



    return (
        <div className="mt-20 pb-44">

            <div>
                <div className="w-fit mx-auto">
                    <Heading classes="text-black font-extrabold text-xl md:text-2xl xl:text-3xl 2xl:text-4xl">
                        {t('13.GRAN_CHACO_IMPACT_NFT', { returnObjects: true })[0]} <br />
                        <span className="text-cusOrenge">{t('13.GRAN_CHACO_IMPACT_NFT', { returnObjects: true })[1]}</span>
                    </Heading>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-8 justify-center items-center gap-8 md:gap-4 xl:gap-20 mxl:gap-24 2xl:gap-28 mt-16 lg:mt-20 xl:mt-24 mxl:mt-28">
                    <div className="text-left md:col-span-4">
                        <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600" style={{ whiteSpace: 'pre-line' }}>
                            {t('13.top_section_description')}
                        </p>
                    </div>

                    <div className="w-full md:col-span-4 h-full rounded-xl overflow-hidden">
                        <img src={chaguarPhoto} alt="" className="w-full h-full object-cover" />
                    </div>
                </div>
            </div>




            <div className="grid grid-cols-1 lg:grid-cols-5 justify-center items-start mt-44 gap-14">
                <div className="lg:col-span-3 xl:col-span-2 h-full rounded-xl overflow-hidden hover:shadow-2xl transition-all duration-300">
                    <video width={320} height={240} controls loop className="w-full md:w-7/12 mx-auto lg:w-full h-full">
                        <source src={nftArtData?.video} type="video/mp4" />
                        <source src={nftArtData?.video} type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="lg:col-span-2 xl:col-span-3 text-left">
                    <h1 className="text-lg xl:text-xl mxl:text-2xl 2xl:text-3xl font-extrabold" style={{ whiteSpace: 'pre-line', }}>{nftArtData?.name}</h1>
                    <h3 className="text-base xl:text-lg mxl:text-xl mt-2">{t('13.price')}</h3>

                    <div className="flex justify-start items-start mt-6">
                        <h5 className="font-extrabold text-base xl:text-lg mxl:text-xl text-cusOrenge">{t('13.Artist')}:</h5>
                        <p className="text-base xl:text-lg mxl:text-xl font-medium ml-3">{t('13.name')} ({t('NFT_Artists')})</p>
                    </div>

                    <div className="mt-3 2xl:mt-5">
                        <h5 className="font-extrabold text-base xl:text-lg mxl:text-xl text-cusOrenge">{t('13.Inspired_by')}:
                            <span className="text-sm xl:text-base mxl:text-lg font-normal text-gray-600 ml-2">{t('13.inspiredBy')}</span></h5>
                    </div>

                    <div className="mt-3 2xl:mt-5">
                        <h5 className="font-extrabold text-base xl:text-lg mxl:text-xl text-cusOrenge">{t('Description')}:
                            <span className="text-sm xl:text-base mxl:text-lg font-normal text-gray-600 ml-2">{t('13.description')}</span></h5>
                    </div>

                    <div className="mt-3 2xl:mt-5">
                        <h5 className="font-extrabold text-base xl:text-lg mxl:text-xl text-cusOrenge">{t('Details')}:
                            <span className="text-sm xl:text-base mxl:text-lg text-gray-600 font-normal ml-2">{t('13.details')}</span></h5>
                    </div>



                    <div className="flex justify-center items-center flex-col mt-8 2xl:mt-12">
                        <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl font-medium text-gray-600">{t('13.Connect_network')}</p>
                        <div className="mt-4">
                            <ConnectWalletModal onlyConnect={true} />
                        </div>


                        <div className="flex justify-between items-center w-fit mx-auto mt-3 2xl:mt-5">
                            <button onClick={handleQuantityDecrease} className={`text-blue-700 bg-gray-200 px-4 py-3 rounded-full flex justify-center items-center w-[44px] lg:w-[52px] h-[44px] lg:h-[52px] text-4xl transition-all duration-500
                            ${mintQuantity <= 0 ? 'opacity-40' : 'opacity-100'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000" className="w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                </svg>
                            </button>

                            <input
                                type="number"
                                name=""
                                id="mintQuantityInput"
                                value={mintQuantity}
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                onChange={(e) => {
                                    if (parseInt(e.target.value) > 5) {
                                        e.target.value = '5'
                                    }
                                }}
                                className="flex-1 bg-gray-200 outline-none border-gray-200 text-xl mxl:text-2xl 2xl:text-3xl 
                                text-black font-black mx-1.5 w-36 sm:w-52 lg:w-64 mxl:w-72 p-1 lg:p-2 text-center rounded-full" />

                            <button onClick={handleQuantityIncrease}
                                className={`text-blue-700  px-4 py-3 rounded-full flex justify-center items-center w-[44px] lg:w-[52px] h-[44px] lg:h-[52px] text-4xl bg-gray-200 transition-all duration-500
                            ${mintQuantity === 5 ? 'opacity-40' : 'opacity-100'}`}>
                                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                            </button>
                        </div>

                        {commonEngine &&
                            commonEngine.mintedAmount >= commonEngine.commonSupply
                            ? <button
                                title="Sold Out"
                                className="text-white font-bold bg-cusOrenge opacity-40 select-none cursor-not-allowed px-16 xl:px-20 py-2 xl:py-3 rounded-xl ml-auto">
                                Sold Out
                            </button>
                            :
                            <button onClick={purchaseTierX} className="text-white font-bold bg-cusOrenge hover:bg-cusRed transition-all duration-300
                                hover:tracking-widest px-10 lg:px-12 xl:px-16 mxl:px-20 py-2 mxl:py-3 rounded-xl mt-3 2xl:mt-6">
                                {t('buy_btn')}
                            </button>
                        }

                    </div>

                </div>
            </div>










            <div className="mt-44">
                <div className="w-fit mx-auto">
                    <Heading classes="text-black font-extrabold text-xl md:text-2xl xl:text-3xl 2xl:text-4xl text-left">
                        {t('13.roadMap.titile1', { returnObjects: true })[0]} <span className="text-cusOrenge">{t('13.roadMap.titile1', { returnObjects: true })[1]}</span>
                    </Heading>
                </div>


                <div className="mt-16 lg:mt-20 xl:mt-24 w-full lg:w-11/12 xl:w-10/12 mxl:w-9/12 mx-auto">
                    <div className="flex justify-start items-start md:items-center flex-col md:flex-row">
                        <div>
                            <div className="w-28 lg:w-32 xl:w-36 mxl:w-40 2xl:w-44 h-28 lg:h-32 xl:h-36 mxl:h-40 2xl:h-44">
                                <img src={roadmapIcon} alt="" className="w-full h-full" />
                            </div>
                        </div>

                        <div className="text-start lg:ml-10 xl:ml-6 mxl:ml-8 2xl:ml-10 w-full md:w-10/12 mt-5 lg:mt-0">
                            <h4 className="text-xl xl:text-2xl mxl:text-3xl 2xl:text-4xl font-extrabold">25% <span className="text-base xl:text-lg mxl:text-xl 2xl:text-2xl">{t('13.roadMap.25%.titile')}</span></h4>

                            <div className="flex justify-start items-start mt-3 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.25%.options', { returnObjects: true })[0]}</p>
                            </div>
                            <div className="flex justify-start items-start mt-2 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.25%.options', { returnObjects: true })[1]}</p>
                            </div>
                            <div className="flex justify-start items-start mt-2 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.25%.options', { returnObjects: true })[2]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end items-start md:items-center flex-col md:flex-row mt-14 lg:mt-20">
                        <div className="text-start mr-10 w-full lg:w-11/12 xl:w-10/12 mxl:w-9/12 mt-5 md:mt-0 order-2 md:order-1">
                            <h4 className="text-xl xl:text-2xl mxl:text-3xl 2xl:text-4xl font-extrabold">50% <span className="text-base xl:text-lg mxl:text-xl 2xl:text-2xl">{t('13.roadMap.50%.titile')}</span></h4>

                            <div className="flex justify-start items-start mt-3 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.50%.options', { returnObjects: true })[0]}</p>
                            </div>
                            <div className="flex justify-start items-start mt-2 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.50%.options', { returnObjects: true })[1]}</p>
                            </div>
                            <div className="flex justify-start items-start mt-2 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.50%.options', { returnObjects: true })[2]}</p>
                            </div>
                        </div>

                        <div className="order-1 md:order-2">
                            <div className="w-28 lg:w-32 xl:w-36 mxl:w-40 2xl:w-44 h-28 lg:h-32 xl:h-36 mxl:h-40 2xl:h-44">
                                <img src={roadmapIcon} alt="" className="w-full h-full" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>




            <div className="mt-28 md:mt-32 lg:mt-36 xl:mt-44">
                <div className="w-fit mx-auto">
                    <Heading classes="text-black font-extrabold text-xl md:text-2xl xl:text-3xl 2xl:text-4xl text-left">
                        {t('13.roadMap.titile2', { returnObjects: true })[0]} <span className="text-cusOrenge">{t('13.roadMap.titile2', { returnObjects: true })[1]}</span>
                    </Heading>
                </div>


                <div className="mt-16 lg:mt-20 xl:mt-24 w-full lg:w-11/12 xl:w-10/12 mxl:w-9/12 mx-auto">
                    <div className="flex justify-start items-start md:items-center flex-col md:flex-row">
                        <div>
                            <div className="w-28 lg:w-32 xl:w-36 mxl:w-40 2xl:w-44 h-28 lg:h-32 xl:h-36 mxl:h-40 2xl:h-44">
                                <img src={roadmapIcon} alt="" className="w-full h-full" />
                            </div>
                        </div>

                        <div className="text-start lg:ml-10 xl:ml-6 mxl:ml-8 2xl:ml-10 w-full md:w-10/12 mt-5 lg:mt-0">
                            <h4 className="text-xl xl:text-2xl mxl:text-3xl 2xl:text-4xl font-extrabold">90% <span className="text-base xl:text-lg mxl:text-xl 2xl:text-2xl">{t('13.roadMap.90%.titile')}</span></h4>

                            <div className="flex justify-start items-start mt-3 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.90%.options', { returnObjects: true })[0]}</p>
                            </div>
                            <div className="flex justify-start items-start mt-2 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.90%.options', { returnObjects: true })[1]}</p>
                            </div>
                            <div className="flex justify-start items-start mt-2 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.90%.options', { returnObjects: true })[2]}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end items-start md:items-center flex-col md:flex-row mt-14 lg:mt-20">
                        <div className="text-start mr-10 w-full lg:w-11/12 xl:w-10/12 mxl:w-9/12 mt-5 md:mt-0 order-2 md:order-1">
                            <h4 className="text-xl xl:text-2xl mxl:text-3xl 2xl:text-4xl font-extrabold">100% <span className="text-base xl:text-lg mxl:text-xl 2xl:text-2xl">{t('13.roadMap.100%.titile')}</span></h4>

                            <div className="flex justify-start items-start mt-3 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.100%.options', { returnObjects: true })[0]}</p>
                            </div>
                            <div className="flex justify-start items-start mt-2 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.100%.options', { returnObjects: true })[1]}</p>
                            </div>
                            <div className="flex justify-start items-start mt-2 ml-4">
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium">-</p>
                                <p className="text-sm xl:text-base mxl:text-lg 2xl:text-xl text-gray-600 font-medium ml-6">
                                    {t('13.roadMap.100%.options', { returnObjects: true })[2]}</p>
                            </div>
                        </div>

                        <div className="order-1 md:order-2">
                            <div className="w-28 lg:w-32 xl:w-36 mxl:w-40 2xl:w-44 h-28 lg:h-32 xl:h-36 mxl:h-40 2xl:h-44">
                                <img src={roadmapIcon} alt="" className="w-full h-full" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
