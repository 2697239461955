import * as React from 'react';
import { Heading } from '../../../Components/Heading/Heading';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";
import { partners_data } from '../../../Data/Partners_data';
import { useTranslation } from 'react-i18next';

export interface IPartnersProps {
}

export function Partners(props: IPartnersProps) {
    const { t } = useTranslation();




    return (
        <div className="mt-44 py-16 bg-gray-300 relative partners_main_box" id="Partners">
            <div className="container mx-auto w-[79%]">


                <div className="mx-auto w-fit">
                    <Heading classes="text-black font-extrabold text-xl md:text-2xl xl:text-3xl 2xl:text-4xl text-left">
                        {t('Our_PARTNERS', { returnObjects: true })[0]} <span className="text-cusOrenge">{t('Our_PARTNERS', { returnObjects: true })[1]}</span>
                    </Heading>
                </div>


                <div className="mt-20">
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 6,
                                spaceBetween: 50,
                            },
                            1380: {
                                slidesPerView: 7,
                                spaceBetween: 50,
                            },
                        }}
                        loop={true}
                        speed={3000}
                        autoplay={{
                            delay: 5,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: false
                        }}
                        watchSlidesProgress={true}
                        grabCursor={true}
                        modules={[Autoplay]}
                    >
                        {partners_data.map(partner =>
                            <SwiperSlide key={partner.id}>
                                <div className="w-28 hover:grayscale">
                                    <img src={partner.logo} alt="" />
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
