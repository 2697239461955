import * as React from 'react';

export interface ILoadingMarkupProps {
}

export function LoadingMarkup(props: ILoadingMarkupProps) {

    return (
        <div className="">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto' }} className="w-28" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="rotate(0 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-1.1655011655011656s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(32.72727272727273 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-1.048951048951049s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(65.45454545454545 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-0.9324009324009324s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(98.18181818181819 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-0.8158508158508159s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(130.9090909090909 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-0.6993006993006993s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(163.63636363636363 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-0.5827505827505828s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(196.36363636363637 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-0.4662004662004662s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(229.0909090909091 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-0.34965034965034963s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(261.8181818181818 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-0.2331002331002331s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(294.54545454545456 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="-0.11655011655011654s" repeatCount="indefinite" />
                    </rect>
                </g><g transform="rotate(327.27272727272725 50 50)">
                    <rect x="46.5" y={21} rx="3.5" ry={8} width={7} height={16} fill="#f53838">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.282051282051282s" begin="0s" repeatCount="indefinite" />
                    </rect>
                </g>
            </svg>

        </div>
    );
}
