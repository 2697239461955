import React from 'react';
import './App.scss';
import { Header } from './Components/Header/Header';
import { AllRoutes } from './Routes';
import { useEagerConnect } from "Hooks/useEagerConnect";
import { Toaster } from 'react-hot-toast';

function App() {
  useEagerConnect();
  return (
    <div className="App">
      <Toaster
        position="top-center"
        toastOptions={{
          success: { duration: 3000 },
          error: { duration: 3000 },
        }}
      />
      <Header />
      <AllRoutes />
    </div>
  );
}

export default App;