import { Contract } from '@ethersproject/contracts'
import ImpactNFT_ABI from 'Contracts/ImpactNFT.json'

export const Networks = {
  ETHMainNet: 1,
  RinkerbyTestNet: 4,
}

export const CONTRACTS_BY_NETWORK = {
  [Networks.ETHMainNet]: {
    ImpactNFT: {
      address: '0x9415a84dF9FcEdeB170c8a88AE8f399148038142',
      abi: ImpactNFT_ABI,
    }
  },
  [Networks.RinkerbyTestNet]: {
    ImpactNFT: {
      // address: '0x67A7FB2326696D3f2F2ACEe05B4034341d6D1850',
      address: '0x68b123FDbc7EE630BE18535F5561F7a50f4389d0',
      abi: ImpactNFT_ABI,
    }
  }
}

export const currentNetwork = process.env.REACT_APP_NETWORK_ID;

export const baseApiUrl = process.env.REACT_APP_API_URL;

export function getContractInfo(name, chainId = null) {
  if (!chainId) chainId = currentNetwork;

  const contracts = CONTRACTS_BY_NETWORK?.[chainId];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}

export function truncateWalletString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(0, 7);
  const endStr = walletAddress.substring(lengthStr - 7, lengthStr);
  return startStr + '...' + endStr;
}

export function truncateHashString(txhash) {
  if (!txhash) return txhash;
  const lengthStr = txhash.length;
  const startStr = txhash.substring(0, 10);
  const endStr = txhash.substring(lengthStr - 10, lengthStr);
  return startStr + '...' + endStr;
}

export function getContractObj(name, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(info.address, info.abi, provider);
}

export function getContractObjWithAddress(name, chainId, provider, contractAddress) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(contractAddress, info.abi, provider);
}

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 6) + '...' + str.slice(-4) : str
