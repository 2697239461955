import "@ethersproject/shims"
import { currentNetwork, getContractObj } from ".";
import { OneOfOneEngineDetail, CommonEngineDetail } from "./typs";
import { BigNumber, ethers } from "ethers";
import { RPC_URLS } from "./connectors";
import toast from "react-hot-toast";


/****************** NFT MINTING ******************/


export async function mintOneOfOne(chainId, provider, account, tokenId, tierNumber) {
    const ImpactNFTContract = getContractObj('ImpactNFT', chainId, provider);
    try {
        const saleStep = await ImpactNFTContract.SALE_STEP();
        if (saleStep.toNumber() !== 1) {
            toast.error("Sale is not opened.");
            return false;
        }

        var nftPrice: BigNumber;
        if (tierNumber === 1) {
            nftPrice = await ImpactNFTContract.TIER1_PRICE();
        } else if (tierNumber === 2) {
            nftPrice = await ImpactNFTContract.TIER2_PRICE();
        } else if (tierNumber === 3) {
            nftPrice = await ImpactNFTContract.TIER3_PRICE();
        }

        const isMinted = await ImpactNFTContract.MAP_MINTED_ONEOFONE(tokenId);

        if (isMinted) {
            toast.error("This NFT is already sold out.");
            return false;
        }

        const tx = await ImpactNFTContract.mintOneOfOne(account, tokenId, tierNumber, {
            value: nftPrice,
            gasLimit: 1000000

        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function mintCommon(chainId, provider, account, numberOfTokens) {
    const ImpactNFTContract = getContractObj('ImpactNFT', chainId, provider);
    try {
        const saleStep = await ImpactNFTContract.SALE_STEP();
        if (saleStep.toNumber() !== 1) {
            toast.error("Sale is not opened.");
            return false;
        }

        var nftPrice: BigNumber = await ImpactNFTContract.TIERX_PRICE();

        const commonSupply = await ImpactNFTContract.COMMON_SUPPLY();
        const mintedAmount = await ImpactNFTContract.MINTED_COMMON_INDEX();

        if (mintedAmount.toNumber() + numberOfTokens > commonSupply.toNumber()) {
            toast.error("Mint count is higher than limit");
            return false;
        }

        const tx = await ImpactNFTContract.mintCommon(account, numberOfTokens, {
            value: nftPrice.mul(numberOfTokens),
            gasLimit: 1000000
        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}



export async function getOneOfOneEngineInfo(tokenId) {
    const jsonProvider = new ethers.providers.JsonRpcProvider(RPC_URLS[currentNetwork]);
    const ImpactNFTContract = getContractObj('ImpactNFT', currentNetwork, jsonProvider);
    try {
        const [
            isMinted,
            tierNumber,
            oneofoneSupply,
            tier1Price,
            tier2Price,
            tier3Price,
        ] = await Promise.all([
            ImpactNFTContract.MAP_MINTED_ONEOFONE(tokenId),
            ImpactNFTContract.MAP_TIER(tokenId),
            ImpactNFTContract.ONEOFONE_SUPPLY(),
            ImpactNFTContract.TIER1_PRICE(),
            ImpactNFTContract.TIER2_PRICE(),
            ImpactNFTContract.TIER3_PRICE(),
        ]);

        const nftMintDetail: OneOfOneEngineDetail = {
            isMinted: isMinted,
            tierNumber: tierNumber.toNumber(),
            oneofoneSupply: oneofoneSupply.toNumber(),
            tier1Price: parseFloat(ethers.utils.formatEther(tier1Price)),
            tier2Price: parseFloat(ethers.utils.formatEther(tier2Price)),
            tier3Price: parseFloat(ethers.utils.formatEther(tier3Price)),
        }

        return nftMintDetail;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getCommonEngineInfo() {
    const jsonProvider = new ethers.providers.JsonRpcProvider(RPC_URLS[currentNetwork]);
    const ImpactNFTContract = getContractObj('ImpactNFT', currentNetwork, jsonProvider);
    try {
        const [
            mintedAmount,
            commonSupply,
            tierXPrice,
        ] = await Promise.all([
            ImpactNFTContract.MINTED_COMMON_INDEX(),
            ImpactNFTContract.COMMON_SUPPLY(),
            ImpactNFTContract.TIERX_PRICE(),
        ]);

        const nftMintDetail: CommonEngineDetail = {
            mintedAmount: mintedAmount.toNumber(),
            commonSupply: commonSupply.toNumber(),
            tierXPrice: parseFloat(ethers.utils.formatEther(tierXPrice)),
        }

        return nftMintDetail;
    } catch (e) {
        console.log(e);
        return null;
    }
}