import Soliman_Lopez from "../Assets/Images/NftArtist/0. Solimán López .jpg";
import Mirna_Teran from "../Assets/Images/NftArtist/1. Mirna Teran.jpg";
import Jimena_Dominguez from "../Assets/Images/NftArtist/2. Jimena Dominguez.jpg";
import Santiago_Toro from "../Assets/Images/NftArtist/3. Santiago Toro.jpg";
import Monica_Fuertes from "../Assets/Images/NftArtist/4. Monica Fuertes.jpg";
import Rodrigo_Reyes from "../Assets/Images/NftArtist/5. Rodrigo Reyes.jpg";


export const nft_artist_data = [
    { id: 1, name: "Soliman Lopez", photo: Soliman_Lopez, },
    { id: 2, name: "Mirna Teran", photo: Mirna_Teran, },
    { id: 3, name: "Jimena Dominguez", photo: Jimena_Dominguez, },
    { id: 4, name: "Santiago Toro", photo: Santiago_Toro, },
    { id: 5, name: "Monica Fuertes", photo: Monica_Fuertes, },
    { id: 6, name: "Rodrigo Reyes", photo: Rodrigo_Reyes, },
]














